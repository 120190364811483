import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Started from './pages/Started';
import backgroundImg from './assets/image/background.png';
import { useTheme } from '@mui/material';

export type PageTypes = 'landing' | 'register' | 'started';

export interface PageProps {
  setPage: (value: PageTypes) => void;
  phone: string;
}

const Router = () => {
  const [currentPage, setPage] = useState<PageTypes>('landing');
  const [phone, setPhone] = useState('');

  const getCurrentPage = () => {
    switch (currentPage) {
      case 'landing':
        return <Landing setPage={setPage} phone={phone} setPhone={setPhone} />;
      case 'register':
        return <Register setPage={setPage} phone={phone} />;
      case 'started':
        return <Started setPage={setPage} phone={phone} />;
      default:
        return null;
    }
  };

  return getCurrentPage();
};

const App = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Router />
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              img: {
                width: '100%',
                height: '100vh',
                objectFit: 'cover'
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'inline-block',
                width: '100%',
                height: '100%',
                opacity: '0.6',
                background: '#fff'
              }
            }}
          >
            <img src={backgroundImg} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;
