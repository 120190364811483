import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material';

const MainTemplate: FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
          width: 450
        }
      }}
    >
      <Typography
        sx={{
          maxWidth: 300,
          [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
            padding: '60px 0 0 30px'
          }
        }}
        variant="h3"
        gutterBottom
      >
        Size Stream MobileFit
      </Typography>
      <Box
        sx={{
          background: blueGrey[50],
          padding: '40px 0',
          position: 'relative',
          [theme.breakpoints.up('sm')]: {
            padding: '40px 60px'
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainTemplate;
