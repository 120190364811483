import React, { FC } from 'react';
import OutlinedInput, {
  outlinedInputClasses
} from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/system';
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';

const StylesOutlinedInput = styled(OutlinedInput)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline} {
    border-color: #f44336;
  }
`);

interface PhoneNumberInputProps {
  label: string;
  value: string;
  handleChange: (value: string, b: any) => void;
  isTouched: boolean;
  setIsTouched: (value: boolean) => void;
  sx?: { [index: string]: string | number };
  error?: boolean;
  helperText?: string;
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  label,
  value,
  handleChange,
  sx = {},
  error,
  helperText,
  isTouched,
  setIsTouched
}) => {
  return (
    <FormControl
      sx={{
        ...sx,
        '& input': { width: '250px' },
        '& .country-list li': { display: 'flex' }
      }}
      size="small"
    >
      <InputLabel
        htmlFor="component-outlined"
        sx={{
          color:
            error && isTouched ? '#f44336 !important' : '#757575 !important'
        }}
      >
        {label}
      </InputLabel>
      <ReactPhoneInput
        isValid={isTouched ? !error : true}
        country="us"
        placeholder={''}
        value={value}
        onChange={handleChange}
        component={StylesOutlinedInput}
        onBlur={() => setIsTouched(true)}
      />
      {isTouched && (
        <FormHelperText sx={{ color: '#f44336 !important' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

interface InputProps {
  label: string;
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  inputComponent?: any;
  sx?: { [index: string]: string | number };
  error?: boolean;
  helperText?: string;
  required?: boolean;
  name?: string;
}

const Input: FC<InputProps> = ({
  label,
  value,
  onChange,
  inputComponent = null,
  sx = {},
  error = false,
  helperText = '',
  required = false,
  name = ''
}) => {
  return (
    <FormControl sx={sx} size="small">
      <InputLabel
        htmlFor="component-outlined"
        sx={{ color: error ? '#f44336 !important' : '#757575 !important' }}
      >
        {label}
      </InputLabel>
      <StylesOutlinedInput
        error={error}
        size="small"
        value={value}
        onChange={onChange}
        label={label}
        inputComponent={inputComponent as any}
        sx={{
          width: '300px'
        }}
        required={required}
        name={name}
      />
      <FormHelperText sx={{ color: '#f44336 !important' }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default Input;
