import axios, { AxiosError, AxiosResponse } from 'axios';

export type RequestError = AxiosError | Error;

export type RequestResponse<T, D> = (
  payload: D
) => Promise<AxiosResponse<T> | RequestError>;

export type StartMobileExperiencePayload = {
  phone?: string;
  email?: string;
};

export const startMobileExperience: RequestResponse<
  null,
  StartMobileExperiencePayload
> = async (payload) => {
  try {
    const res = await axios.post<null>('api/start', payload);
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

interface User {
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
}

interface RegisterParams {
  phone?: string;
  email?: string;
  firstName: string;
  lastName: string;
}

export const registerUser: RequestResponse<User, RegisterParams> = async (
  payload
) => {
  try {
    const res = await axios.post<User>('api/register', payload);
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};
