import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { PhoneNumberInput } from '../components/Input';
import { startMobileExperience } from '../api';
import { PageProps } from '../App';

interface LandingProps {
  setPhone: (value: string) => void;
}

const Landing: FC<PageProps & LandingProps> = ({
  setPage,
  phone,
  setPhone
}) => {
  const [error, setError] = useState('Invalid phone number');
  const [isTouched, setIsTouched] = useState(false);
  const theme = useTheme();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsTouched(true);

    if (error) {
      return;
    }

    const res = await startMobileExperience({ phone: `+${phone}` });
    if ('status' in res && res.status === 200) {
      setPage('started');
    } else {
      setPage('register');
    }
  };

  const phoneNumberHandler = (value: string, data: any) => {
    setPhone(value);
    const correctLength = data.format.match(/\./g).length;
    if (correctLength !== value.length) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };

  return (
    <MainTemplate>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
              padding: '0 30px'
            }
          }}
        >
          Enter your phone number so we can get started finding your fit!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            <PhoneNumberInput
              label="Phone"
              value={phone}
              handleChange={phoneNumberHandler}
              isTouched={isTouched}
              setIsTouched={setIsTouched}
              sx={{ marginBottom: 2 }}
              error={!!error}
              helperText={error}
            />
            <Button
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
            >
              Continue in App
            </Button>
          </form>
          <Typography variant="subtitle2">SMS charges may apply</Typography>
        </Box>
      </Box>
    </MainTemplate>
  );
};

export default Landing;
